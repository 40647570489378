
/**
 * NOTE: Currently this file is only used by the 'Register' page, but is displayed
 *         within the 'OnboardLayout'. The file is stored here to group it with its
 *         screens, but can be moved if a better place is found!
 */

import { Component, Prop, Vue } from 'vue-property-decorator';

// Config Variables
import { ActiveTenant } from '../../../../active-tenant';
import { tenants } from '../../../../tenant-config';

const StepStateColor = {
  active: tenants[ActiveTenant].onboarding.currentStepColor,
  pending: tenants[ActiveTenant].onboarding.pendingStepColor
};

export interface IOnboardingStep {
  /** Step description */
  description: string;
  /** Step icon (must be from '@mdi/js'!) */
  icon: string;
  /** Step title */
  title: string;
  stepKey: number;
}

@Component
export default class OnboardingStep extends Vue {
  @Prop() step!: IOnboardingStep;
  @Prop({ default: 1 }) currentStepKey!: number;

  stepTextColor = StepStateColor.pending;

  beforeMount() {
    const { stepKey } = this.step;
    if (stepKey === this.currentStepKey) {
      this.stepTextColor = StepStateColor.active;
      return;
    }

    if (stepKey < this.currentStepKey) {
      this.stepTextColor = StepStateColor.pending;
    }
  }
}
