
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiAccountCircleOutline, mdiCreditCard, mdiShieldCheckOutline, mdiCellphoneText } from '@mdi/js';

import { Title } from './common';

// Components
import OnboardingStep, { IOnboardingStep } from '../views/auth/components/OnboardingStep.vue';
import OnboardingHeader from '../components/OnboardingHeader.vue';
import AppHeaderImages from '@/components/offers/AppHeaderImages.vue';

// Config Variables
import { ActiveTenant } from '../../active-tenant';
import { tenants } from '../../tenant-config';

@Component({
  components: {
    OnboardingHeader,
    OnboardingStep,
    Title,
    AppHeaderImages
  }
})
export default class OnboardLayout extends Vue {
  @Prop({ default: false })
  showButtons!: boolean;
  @Prop()
  currentStepKey!: number;

  fullPage!: boolean | string;

  buttonBackground = tenants[ActiveTenant].onboardLayoutButtonBackground;
  buttonBorder = tenants[ActiveTenant].onboardLayoutButtonBorder
    ? `2px solid ${tenants[ActiveTenant].onboardLayoutButtonBorder}`
    : null;
  onboardBanner = tenants[ActiveTenant].onboardImage;
  onboardingBackgroundColor = tenants[ActiveTenant].onboardLayoutBackground;
  onboardLayoutTitleColor = tenants[ActiveTenant].onboardLayoutTitleColor;
  onboardLayoutTitleText = tenants[ActiveTenant].onboardLayoutTitleText;
  onboardFirstStepTitleText = tenants[ActiveTenant].onboardFirstStepTitleText;
  onboardFirstStepDescriptionText = tenants[ActiveTenant].onboardFirstStepDescriptionText;

  onboardingSteps: IOnboardingStep[] = [
    {
      description: this.onboardFirstStepDescriptionText,
      icon: mdiAccountCircleOutline,
      title: this.onboardFirstStepTitleText,
      stepKey: 1
    },
    {
      description: 'Your card information is always kept safe and secure.',
      icon: mdiCreditCard,
      title: 'Enroll an eligible Visa or Mastercard',
      stepKey: 2
    },
    {
      description: 'Let us know how you’d like to be contacted.',
      icon: mdiCellphoneText,
      title: 'Set Your Notification Preferences',
      stepKey: 3
    },
    {
      description: 'Confirm your email address, and you’re all set.',
      icon: mdiShieldCheckOutline,
      title: 'Validate Your Account',
      stepKey: 4
    }
  ];

  scrollToEnroll(event: any): void {
    const id = `enroll-form`;
    const yOffset = -88 - 10;
    const element = document.getElementById(id);
    if (element) {
      const y = (element?.getBoundingClientRect().top ?? 0) + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  get computedFullPage(): string | boolean {
    return this.fullPage || this.fullPage === '';
  }

  get isSignUp(): boolean {
    return this.$route.name === 'register';
  }

  get isLogin(): boolean {
    return this.$route.name === 'login';
  }

  get isForgot(): boolean {
    return this.$route.name === 'forgotPassword';
  }

  get TDLlogo(): string {
    return require(`@/assets/TDL-logo.png`);
  }

  get LeasideLocalLogo(): string {
    return require(`@/assets/logo-leasideLocal.png`);
  }
}
