var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',{staticClass:"auth-layout__main"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('OnboardingHeader'),_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align-content":"start","justify":"center"}},[_c('v-col',{staticClass:"d-none d-lg-flex flex-column fill-height",style:({ backgroundColor: _vm.onboardingBackgroundColor }),attrs:{"cols":"6"}},[_c('div',{staticClass:"hidden-md-and-down onboarding-banner is-tiled",class:{
            'fill-height': _vm.isLogin || _vm.isForgot,
            'onboarding-banner-signup': _vm.isLogin || _vm.isForgot
          },style:({
            backgroundImage: `url('')`
          })},[_c('AppHeaderImages',{attrs:{"isOnboard":true}})],1),(!_vm.isLogin && !_vm.isForgot)?_c('div',{staticClass:"d-flex flex-column justify-center align-center auth-layout__title px-16 py-4"},[_c('div',[_c('div',{staticClass:"auth-layout__desc--onboarding",style:({ color: _vm.onboardLayoutTitleColor })},[_vm._v(" "+_vm._s(_vm.onboardLayoutTitleText)+" ")]),_c('div',{staticClass:"app-onboard__onboarding-steps"},_vm._l((_vm.onboardingSteps),function(step){return _c('OnboardingStep',{key:step.stepKey,attrs:{"currentStepKey":_vm.currentStepKey,"step":step}})}),1),_c('div',{class:[{ 'd-none': !this.showButtons }, 'flex-column', 'mt-12']},[_c('div',{staticClass:"my-6"},[_c('v-btn',{staticClass:"auth-layout__button",style:({ backgroundColor: _vm.buttonBackground, border: _vm.buttonBorder }),attrs:{"depressed":"","to":"/login"}},[_vm._v(" I’m already registered! Sign in ")])],1),_c('div',{staticClass:"my-6 d-lg-none"},[_c('v-btn',{staticClass:"auth-layout__button",style:({ backgroundColor: _vm.buttonBackground, border: _vm.buttonBorder }),attrs:{"depressed":""},on:{"click":_vm.scrollToEnroll}},[_vm._v(" Enroll Now ")])],1)])])]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"hidden-lg-and-up onboarding-banner is-tiled",style:({
            backgroundImage: `url('')`
          })},[_c('AppHeaderImages',{attrs:{"isOnboard":true}})],1),_c('div',{staticClass:"d-flex flex-column align-center"},[(!_vm.isLogin && !_vm.isForgot)?_c('div',{staticClass:"hidden-lg-and-up onboarding-steps-list pa-6",style:({ backgroundColor: _vm.onboardingBackgroundColor }),attrs:{"id":"enroll"}},[_c('div',{class:[{ 'd-none': !this.showButtons }, 'my-6']},[_c('v-btn',{staticClass:"auth-layout__button mb-4",style:({ backgroundColor: _vm.buttonBackground, border: _vm.buttonBorder }),attrs:{"depressed":""},on:{"click":_vm.scrollToEnroll}},[_vm._v(" ENROLL NOW ")])],1),_c('div',{staticClass:"auth-layout__desc--onboarding",style:({ color: _vm.onboardLayoutTitleColor })},[_vm._v(" "+_vm._s(_vm.onboardLayoutTitleText)+" ")]),_c('div',{staticClass:"app-onboard__onboarding-steps"},_vm._l((_vm.onboardingSteps),function(step){return _c('OnboardingStep',{key:step.stepKey,attrs:{"currentStepKey":_vm.currentStepKey,"step":step}})}),1),_c('div',{class:[{ 'd-none': !this.showButtons }, 'my-6']},[_c('v-btn',{staticClass:"auth-layout__button",style:({ backgroundColor: _vm.buttonBackground, border: _vm.buttonBorder }),attrs:{"depressed":"","to":"/login"}},[_vm._v(" I’M ALREADY REGISTERED! SIGN IN ")])],1)]):_vm._e(),_c('div',{staticClass:"auth-layout__right__content"},[_vm._t("default")],2)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }